<template>
	<div>
		<v-card class="elevation-6 card-login" :loading="loading">
			<v-card-text class="pa-0" v-if="!load">
				<v-row>
					<v-col cols="12" class="px-11 py-5">
						<v-row>
							<v-row justify="center" class="text-center">
								<v-col cols="4" class="d-felx justify-center">
								  <v-img
						        contain
						        max-width="150"
						        :src="require('@/assets/Todo-carta-logo.png')"
						      ></v-img>      			
								</v-col>
							</v-row>
							<v-col cols="12" class="pt-0 px-10">										
								<h3 class="primary--text py-1" v-text="'Olvido de Contraseña'"></h3>
								<base-divider color="orange" dense align="left" max-width="35" space="2" />
								<span class="font-weight-light blue-grey--text" style="font-size: 1rem">Ingrese el Email del restaurante que tiene registrado en nuestro sistema para resetear su contraseña.</span>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" class="px-10">
								<validation-observer ref="observerEmail">
									<v-form>
										<validation-provider name="Email" rules="required|email" v-slot="{ errors }">
											<v-text-field v-model="form.email" outlined label="Email" name="Email" prepend-inner-icon="mdi-account-circle" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="loading" @keyup.enter="validateEmail"/>
											</v-text-field>
										</validation-provider>
									</v-form>
								</validation-observer>				
							</v-col>
						</v-row>
						<v-row justify="center" class="text-center">
							<v-col cols="8" class="px-10">
								<v-btn block color="primary" @click="validateEmail" :loading="loading">Enviar Email</v-btn>            			
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-text class="pa-0" v-if="load">
				<v-row>
					<v-col cols="12" class="px-11 py-5">
						<v-row>
							<v-row justify="center" class="text-center">
								<v-col cols="4" class="d-felx justify-center mt-4">
									<div class="v-title__icon_large">
								 		<v-icon size="120" color="success">mdi-email</v-icon>
								 	</div>    			
								</v-col>
							</v-row>
							<v-col cols="12" class="pa-10 text-center">										
								<h2 class="primary--text pb-6" v-text="'Email Enviado con Éxito'"></h2>
								<span class="font-weight-light blue-grey--text pt-5" style="font-size: 1.1rem" v-text="message" />
							</v-col>
						</v-row>
						<v-row justify="center" class="text-center">
							<v-col cols="8" class="px-10">
								<v-btn block color="primary" @click="$router.push({name: 'login'})" :loading="loading">Continuar</v-btn>         			
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<base-material-snackbar
	      v-model="snackbar.active"
	      type="error"
	      bottom
	      center
	    >
	    	{{ snackbar.text }}
	    </base-material-snackbar>
	</div>
	

</template>
<script>
export default {
	data() {
		return {
			form:{
				email: ''
			},
			loading: false,
			snackbar:{
				text:'',
				active: false
			},
			load: false,
			message: ''
		}
	},
	methods: {
		validateEmail(){
			this.$refs.observerEmail.validate().then(result =>{
				if(result){
					this.sendEmail();
				}
			});
		},
		async sendEmail(){
			this.loading = true;
			try{
				const { data } = await this.$api.post('api/auth/reset/password', this.form);

				if(data){
					this.load = true;
					this.message = data.message;
				}
			}
			catch(e){
				this.snackbar = {
					text:e.response.data.message,
					active: true
				};
			}
			finally{
				this.loading = false;
			}
		}
	},
}
</script>
<style lang="sass">
.v-title__icon_large .v-icon
  align-self: center
  height: 128px
  min-width: 128px

.v-title__icon_large .v-icon:after
  background: currentColor !important
  border-radius: 50%
  bottom: 0
  content: ""
  left: 0
  opacity: 0.16
  position: absolute
  right: 0
  top: 0
</style>
